<template>
  <div>
    <div v-if="showDetail===2">
      <div class="box">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item><span @click="goback(1)">合作伙伴</span></el-breadcrumb-item>
          <el-breadcrumb-item>公司列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="picBox">
          <!-- 一级 -->
          <!-- <el-row v-if="showList===1" :gutter="20">
            <el-col v-for="(item, index) in companyList" :key="index" :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="item-containerA">
              <img :src="getImg(item.logo)" @error="imgError" @click="detailFun(item)">
            </el-col>
          </el-row> -->
          <!-- 二级 -->
          <el-row :gutter="20">
            <el-col v-for="(item, index) in companyList" :key="index" :xs="8" :sm="8" :md="8" :lg="8" :xl="8" class="item-containerB">
              <img :src="getImg(item.enterLogo)" @error="imgError" @click="detailFun(item)">
            </el-col>
          </el-row>
          <!-- 三级 -->
          <!-- <el-row v-if="showList===3" :gutter="20">
            <el-col v-for="(item, index) in companyList" :key="index" :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="item-containerC">
              <img :src="getImg(item.logo)" @error="imgError" @click="detailFun(item)">
            </el-col>
          </el-row> -->
        </div>
      </div>
      <el-pagination class="pageAction" :current-page="currentPage" :page-size="12" :background="false" layout="prev, pager, next, jumper" :total="total" style="text-align: center;" @current-change="handleCurrentChanges" />

    </div>
    <detail-page v-if="showDetail===3" :detail-data="detailData" :item-source="itemSource" @close="close"></detail-page>
  </div>
</template>

<script>

import backgroundImage from '@/assets/images/talent/appraise-top.png'
import listMixin from '../../../../../utils/mixins/listMixin'
import detailPage from './detailPage'
export default {
  components: {
    detailPage
  },
  mixins: [listMixin],
  props: {
    showList: {
      type: Number,
      default() {
        return 1
      }
    }
  },
  data() {
    return {
      companyList: [],
      // 2公司列表,3公司详情
      showDetail: 2,
      itemSource: 2,
      detailData: {}
    }
  },
  computed: {
    parkData() {
      return this.$store.getters.parkData
    }
  },
  mounted() {
    document.body.scrollTop = 0
    this.requestData()
    document.getElementById('app').scrollTop = 0
  },
  methods: {
    // 加默认封面
    getImg(item) {
      if (item) return item
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    requestData() {
      this.toggleLoading(true)
      this.$get(this.urls.corpList, this.searchForm, [
        this.currentPage,
        12
      ])
        .then((result) => {
          this.toggleLoading(false)
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.companyList = data.records
            this.total = result.data.total
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    // 查看详情
    handleDetail(item) {
    },
    goback(num) {
      this.$emit('close', num)
    },
    detailFun(item) {
      this.detailData = item
      this.showDetail = 3
      this.itemSource = 2
    },
    close(num) {
      this.$emit('close', num)
      this.showDetail = num
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  box-sizing: border-box;
  padding: 20px 0;
  max-width: 1280px;
  margin: 0 auto;
}
.banner {
  width: 100%;
  height: 430px;
}
.picBox {
  max-width: 1280px;
  margin: 30px auto;
}
img {
  background-image: url("../../../../../assets/images/appraise-top.png");
  background-size: 100% 100%;
  background-position: 50% 50%;
  width: 100%;
  overflow: hidden;
}
.item-containerA {
  img {
    height: 280px;
  }
}
.item-containerB {
  margin: 5px 0;
  img {
    height: 250px;
  }
}
.item-containerC {
  img {
    height: 180px;
  }
}
.breadcrumb {
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
