<template>
  <div>
    <div class="box">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item><span @click="goback(1)">合作伙伴</span></el-breadcrumb-item>
        <el-breadcrumb-item v-if="itemSource===2"><span @click="goback(2)">公司列表</span></el-breadcrumb-item>
        <el-breadcrumb-item>公司详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="companyBox">
        <el-row :gutter="20">
          <div>
            <swiper :options="swiperOption">
              <swiper-slide v-for="(item,index) in companyList.photos" :key="index">
                <!-- <a :href="item.jumpUrl" target="_blank"> -->
                <img :src="item.filePath" alt="" class="bannerImg">
                <!-- </a> -->
              </swiper-slide>
              <div slot="pagination" class="swiper-pagination"></div>
            </swiper>
          </div>
        </el-row>
        <div class="company-name" style="margin-top:20px;">
          <img :src="getImg(companyList.enterLogo)" class="logo" @error="imgError">
          <span>{{ companyList.enterName }}</span>
        </div>
        <h3 class="company-introduce">核心产品</h3>
        <p class="text">核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品核心产品</p>
        <h3 class="company-introduce">企业优势</h3>
        <p class="text">企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势企业优势</p>
        <h3 class="company-introduce">公司介绍</h3>
        <p class="text">{{ companyList.enterDetails }}</p>
        <h3 class="company-introduce">视频介绍</h3>
        <div>
          <el-row :gutter="20">
            <el-col v-for="(item, index) in companyList.videos" :key="index" :xs="8" :sm="8" :md="8" :lg="8" :xl="8" class="item-containerB">
              <video id="video" controls="controls" controlsList="nodownload" style="width: 100%;height: 100%;object-fit: fill" :src="item.filePath">
              </video>
            </el-col>
          </el-row>
        </div>
        <h3 class="company-introduce">联系方式</h3>
        <span v-if="companyList.enterEmail" class="attribute" style="margin-right:40px;">
          <i class="el-icon-message"></i>
          {{ companyList.enterEmail }}
        </span>
        <span class="attribute" style="margin-right:20px;">
          <i v-if="companyList.enterAddress" class="el-icon-office-building"></i>
          {{ companyList.enterAddress }}
        </span>
        <span class="attribute" style="margin-right:20px;">
          <i v-if="companyList.enterWebsite" class="el-icon-price-tag"></i>
          {{ companyList.enterWebsite }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import backgroundImage from '@/assets/images/talent/appraise-top.png'
export default {
  components: {
  },
  props: {
    detailData: {
      type: Object,
      default() {
        return {}
      }
    },
    itemSource: {
      type: Number,
      default() {
        return 1
      }
    }
  },
  data() {
    return {
      swiperOption: {
        watchOverflow: true,
        slidesPerView: 3,
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000 // 5秒切换一次
        },
        // // 设置轮播可循环
        loop: true
      },
      companyList: []
    }
  },
  computed: {
  },
  mounted() {
    document.body.scrollTop = 0
    this.requestData()
    document.getElementById('app').scrollTop = 0
  },
  methods: {
    // 加默认封面
    getImg(item) {
      if (item) return item
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    requestData() {
      this.toggleLoading(true)
      this.$get(this.urls.corpDetails, this.searchForm, [this.detailData.enterId])
        .then((result) => {
          this.toggleLoading(false)
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.companyList = data
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    goback(num) {
      this.$emit('close', num)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  box-sizing: border-box;
  padding: 20px 0;
  max-width: 1280px;
  margin: 0 auto;
}
.banner {
  width: 100%;
  height: 430px;
}
.picBox {
  max-width: 1280px;
  margin: 30px auto;
}
img {
  background-image: url("../../../../../assets/images/appraise-top.png");
  background-size: 100% 100%;
  background-position: 50% 50%;
  width: 100%;
  overflow: hidden;
}
.breadcrumb {
  margin-bottom: 20px;
  cursor: pointer;
}
.company-name {
  display: flex;
  align-items: center;
  .logo {
    width: auto;
    height: 60px;
    margin-right: 18px;
    border-radius: 10px;
  }
}
.attribute {
  color: #858585;
  font-size: 14px;
  line-height: 35px;
  i {
    font-size: 20px;
    margin-right: 20px;
    vertical-align: middle;
  }
}
.company-introduce {
  font-size: 20px;
  font-weight: 400;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
.company-introduce::after {
  content: "";
  width: 80px;
  height: 4px;
  position: absolute;
  left: 0;
  bottom: -13px;
  background: linear-gradient(90deg, #f5cd4f, #f4a522);
  border-radius: 2px;
}
.companyBox {
  padding: 20px;
  background: #fff;
}
.text {
  margin-top: 10px;
  line-height: 40px;
}
.item-containerB {
  video {
    height: 200px !important;
  }
}
.bannerImg {
  width: 400px;
  height: 300px;
}
.title {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  span {
    font-size: 14px;
    font-weight: normal;
  }
}
.swiper-slide {
  display: flex;
  justify-content: space-around;
}
</style>
