<template>
  <div>
    <div v-if="showDetail===1" class="box">
      <swiperbanner class="banner" :dict-code="Banner.BANNER_PARTNERS"></swiperbanner>

      <div class="picBox">
        <!-- 一级 -->
        <!-- <div class="title">
          <span>铂金会员</span>
          <div @click="moreFun(1)">更多></div>
        </div>
        <el-row :gutter="20">
          <el-col v-for="(item, index) in companyList" :key="index+'a'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="item-containerA">
            <img :src="getImg(item.logo)" @error="imgError" @click="detailFun(item)">
          </el-col>
        </el-row>
        <div class="title"><span>黄金会员</span>
        <div @click="moreFun(2)">更多></div>
        </div> -->
        <el-row :gutter="20">
          <el-col v-for="(item, index) in companyList" :key="index+'b'" :xs="8" :sm="8" :md="8" :lg="8" :xl="8" class="item-containerB">
            <img :src="getImg(item.enterLogo)" @error="imgError" @click="detailFun(item)">
          </el-col>
        </el-row>
        <div class="more" @click="moreFun(2)">查看更多 ></div>
        <!-- <div class="title"><span>白银会员</span>
          <div @click="moreFun(3)">更多></div>
        </div>
        <el-row :gutter="20">
          <el-col v-for="(item, index) in companyList" :key="index+'c'" :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="item-containerC">
            <img :src="getImg(item.logo)" @error="imgError" @click="detailFun(item)">
          </el-col>
        </el-row> -->
      </div>
      <!-- <div style="text-align: center">
        <el-pagination class="pageAction" :current-page="currentPage" :page-size="12" :background="false" layout="prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
      </div> -->
    </div>

    <more-page v-if="showDetail===2" :show-list="showList" @close="close"></more-page>
    <detail-page v-if="showDetail===3" :detail-data="detailData" :item-source="itemSource" @close="close"></detail-page>
  </div>
</template>

<script>
import Swiperbanner from '../../../../components/swiperbanner.vue'
import { Banner } from '../../../../utils/dict'
import backgroundImage from '@/assets/images/talent/appraise-top.png'
import morePage from './components/morePage'
import detailPage from './components/detailPage'
import listMixin from '../../../../utils/mixins/listMixin'
export default {
  components: {
    Swiperbanner,
    morePage,
    detailPage
  },
  mixins: [listMixin],
  data() {
    return {
      Banner,
      companyList: [],
      // 2公司列表,3公司详情
      showDetail: 1,
      showList: 1,
      detailData: {},
      itemSource: 1

    }
  },
  computed: {
    parkData() {
      return this.$store.getters.parkData
    }
  },
  mounted() {
    document.body.scrollTop = 0
    this.requestData()
    document.getElementById('app').scrollTop = 0
  },
  methods: {
    // 加默认封面
    getImg(item) {
      if (item) return item
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    requestData() {
      this.toggleLoading(true)
      this.$get(this.urls.corpList, this.searchForm, [1, 9])
        .then((result) => {
          this.toggleLoading(false)
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.companyList = data.records
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    // 查看详情
    handleDetail(item) {
    },
    // 更多
    moreFun(num) {
      this.showList = num
      this.showDetail = num
    },
    close(num) {
      this.showDetail = num
    },
    // 公司详情
    detailFun(item) {
      this.detailData = item
      this.showDetail = 3
      this.itemSource = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  box-sizing: border-box;
}
.banner {
  width: 100%;
  height: 430px;
}
.picBox {
  max-width: 1280px;
  margin: 30px auto;
}
img {
  background-image: url("../../../../assets/images/appraise-top.png");
  background-size: 100% 100%;
  background-position: 50% 50%;
  width: 100%;

  overflow: hidden;
}
.item-containerA {
  img {
    height: 280px;
  }
}
.item-containerB {
  margin: 5px 0;
  img {
    height: 250px;
  }
}
.item-containerC {
  img {
    height: 180px;
  }
}
.title {
  position: relative;
  color: #252b3a;
  text-align: center;
  padding: 30px;
  width: 100%;
  line-height: 46px;
  font-size: 24px;
  font-weight: inherit;
  color: #252b3a;
  font-family: Hiragino Sans GB, Helvetica Neue, Helvetica, STHeiTi,
    Microsoft Yahei, sans-serif;
  div {
    position: absolute;
    right: 0px;
    top: 30px;
    color: #aba9a9;
    font-size: 16px;
  }
}
.title span {
  position: relative;
}

.title span:after {
  position: absolute;
  content: " ";
  left: -16px;
  top: 50%;
  margin-top: -10px;
  width: 4px;
  height: 20px;
  background: linear-gradient(135deg, #2791ff 0, #03a7ff 98%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.more {
  margin: 15px;
  font-size: 16px;
  line-height: 40px;
  color: #575d6c;
  text-align: center;
  cursor: pointer;
  width: 100%;
}
</style>
